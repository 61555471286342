import React from 'react';

import styles from './Logo.module.css';
// make webpack aware that this image is used. Won't be mixed with JS
import burgerLogo from '../../assets/images/burger-logo.png';

const logo = (props) => (
    <div className={styles.Logo} style={{height: props.height}}>
        {/* This will not work b/c src folder is only in dev and webpack will 
            bundle them together. 
        <img src='../../assets/images/burger-logo.png'/> */}
        <img src={burgerLogo} alt="MyBurger"/>
    </div>
);



export default logo;